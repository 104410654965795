<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-snackbar
                v-model="showPopup"
                :timeout="timeout"
                color="success"
                top
                center
                >
                {{ this.message }}
            </v-snackbar>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            :loading="api.isLoading"
            class="sticky-table"
            :items="data"
            :search="search">
            <template v-slot:header="">
                <thead>
                <tr>
                    <th v-for="(header, index) in dataHeader" :key="index" :class="index === 0 ? 'sticky-column' : ''">
                    {{ header.text }}
                    </th>
                </tr>
                </thead>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Top search keyword
                                </v-toolbar-title>
                            </v-row>
                            
                            <AFilterPaginate
                                :filterHeaders="filterHeader"
                                :data="data"
                                :startDate="startDate"
                                :endDate="endDate"
                                @reset="(response)=>{
                                    fetch();
                                }"
                                @filter="(startDate,endDate,keyword,category) => {
                                    applyFilter(startDate,endDate,keyword,category);
                                }"
                                @refresh="fetch"/>
                                <div class="d-flex justify-start" >
                                <v-btn
                                    color="primary"
                                    style="margin-top:-68px"
                                    small
                                    @click="downloadReport"
                                    plain>
                                    Download Report
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
            <template v-slot:body="{items}">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td class="sticky-column"
                        @click="copyText(item.keyword)">
                            <ACopyAbleText :text="item.keyword">
                            </ACopyAbleText>
                        </td>
                        <td
                        @click="copyText(item.count)">
                            <ACopyAbleText :text="item.count">
                            </ACopyAbleText>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import excelUtils from "../../utils/excelUtils";
    export default {
    components:{
    ASuccessWithoutReload,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        showPopup:false,
        message:"Text copied succesfully",
        timeout:2000,
        startDate:null,
        endDate:null,
        data:[],
        dataHeader:[
            {
                text:'Keyword',
                value:'keyword'
            },
            
            {
                text:'Count (unique)',
                value:'count'
            },
            
        ],
        filterHeader:[
            {
                text:"Keyword",
                value:"keyword",
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            
            if(resp.class === "getTopSearchKeyword") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let url =  process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/developer/tracker/search/keyword/popular";
            this.$api.fetch(this.fetchTopSearchKeyword(url));
        },
        fetchTopSearchKeyword(url) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = url;
            return tempApi;
        },
        applyFilter(startDate,endDate,keyword,category) {
            let url = process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/developer/tracker/search/keyword/popular?startDate="+startDate+"&endDate="+endDate+"&keyword="+keyword
            this.startDate = startDate;
            this.endDate = endDate;
            this.keyword = keyword;
            this.category = category;
            this.$api.fetch(this.fetchTopSearchKeyword(url));
        },
    
        processSearchResult(data) {
            data.forEach(element => {
                if(element.created_at != null) {
                    element.created_at = this.$moment(element.created_at).format("YYYY-MM-DD HH:mm:ss");
                }
                element.is_found_content = element.is_found_content == true?"Found": "Empty";
                element.is_open_content = element.is_open_content == true ? "Open" : "Not open";
            })
            return data;
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        processRenewalStatus(data) {
            data.forEach(element => {
                //Process the json
                let jsonData = JSON.parse(element.renewal);
                if (jsonData !== null) {
                    element.renewal_status = jsonData.renewal_status;
                    element.status = jsonData.status;
                    element.renewal_created_date = this.$moment(jsonData.created_at).format('YYYY-MM-DD HH:mm:ss');
                }
            });
            return data;
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            this.showPopup = true;
        },
        handleClosePopup() {
            this.showPopup = false;
        },
        downloadReport() {
            try{
                let downloadData = null;
                console.log(this.data);
                if(this.data.length > 0) {
                    downloadData = this.data.map(item=>{
                        return {
                            "Keyword":item.keyword,
                            "Count":item.count,
                        }
                    });
                }
                else {
                    downloadData = [
                        {
                          "keyword":"",
                          "Count":"",
                        }
                    ]
                }
                excelUtils.exportToExcel(downloadData,"top_search_keyword.xlsx");
            }catch(error) {
                alert(error)
            }
        }
    }
}
</script>
<style>
.sticky-table {
    overflow-x:scroll;
}
.sticky-column {
    position:sticky;
    left:0;
    z-index:1;
    background:#f8f9fa;
    border-right: 1px solid #ddd;
}
td {
    min-width:200px;
    text-align:center;
    font-family: Arial, sans-serif;
    border-right: 1px solid #ddd;
}
th {
    text-align:center!important;
    background:#f8f9fa;
    border-top:1px solid #ddd;
    border-right: 1px solid #ddd;
}

.v-data-footer {
    background-color:#f8f9fa;
}
</style>