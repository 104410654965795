var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0" },
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: { close: _vm.closeSuccessModal },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: _vm.timeout,
            color: "success",
            top: "",
            center: "",
          },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [_vm._v(" " + _vm._s(this.message) + " ")]
      ),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            staticClass: "sticky-table",
            attrs: {
              loading: _vm.api.isLoading,
              items: _vm.data,
              search: _vm.search,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("thead", [
                      _c(
                        "tr",
                        _vm._l(_vm.dataHeader, function (header, index) {
                          return _c(
                            "th",
                            {
                              key: index,
                              class: index === 0 ? "sticky-column" : "",
                            },
                            [_vm._v(" " + _vm._s(header.text) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "text-h4 pt-4",
                        attrs: { flat: "", height: "auto" },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "mt-3 mx-3" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "v-toolbar-title",
                                      {
                                        staticClass:
                                          "font-weight-bold text-h4 my-3",
                                      },
                                      [_vm._v(" Top search keyword ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("AFilterPaginate", {
                                  attrs: {
                                    filterHeaders: _vm.filterHeader,
                                    data: _vm.data,
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                  },
                                  on: {
                                    reset: function (response) {
                                      _vm.fetch()
                                    },
                                    filter: function (
                                      startDate,
                                      endDate,
                                      keyword,
                                      category
                                    ) {
                                      _vm.applyFilter(
                                        startDate,
                                        endDate,
                                        keyword,
                                        category
                                      )
                                    },
                                    refresh: _vm.fetch,
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: { "margin-top": "-68px" },
                                        attrs: {
                                          color: "primary",
                                          small: "",
                                          plain: "",
                                        },
                                        on: { click: _vm.downloadReport },
                                      },
                                      [_vm._v(" Download Report ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function (ref) {
                  var items = ref.items
                  return [
                    _c(
                      "tbody",
                      _vm._l(items, function (item) {
                        return _c("tr", { key: item.id }, [
                          _c(
                            "td",
                            {
                              staticClass: "sticky-column",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(item.keyword)
                                },
                              },
                            },
                            [
                              _c("ACopyAbleText", {
                                attrs: { text: item.keyword },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(item.count)
                                },
                              },
                            },
                            [
                              _c("ACopyAbleText", {
                                attrs: { text: item.count },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }